<template>
	<div class="container">
		<h1>Dashboard</h1>
		<v-container grid-list-md>
			<v-layout wrap>
				<v-flex md12 sm12 lg4>
					<material-chart-card
						:data="dailySalesChart.data"
						:options="dailySalesChart.options"
						color="info"
						type="Line"
					>
						<h4 class="title font-weight-light">Daily Sales</h4>
						<p class="category d-inline-flex font-weight-light">
							<v-icon
								color="green"
								small
							>
								mdi-arrow-up
							</v-icon>
							<span class="green--text">55%</span>&nbsp;
							increase in today's sales
						</p>
						<template slot="actions">
							<v-icon
								class="mr-2"
								small
							>
								mdi-clock-outline
							</v-icon>
							<span class="caption grey--text font-weight-light">updated 4 minutes ago</span>
						</template>
					</material-chart-card>
				</v-flex>
				<v-flex md12 sm12 lg4>
					<material-chart-card
						:data="emailsSubscriptionChart.data"
						:options="emailsSubscriptionChart.options"
						:responsive-options="emailsSubscriptionChart.responsiveOptions"
						color="red"
						type="Bar"
					>
						<h4 class="title font-weight-light">Email Subscription</h4>
						<p class="category d-inline-flex font-weight-light">Last Campaign Performance</p>

						<template slot="actions">
							<v-icon
								class="mr-2"
								small
							>
								mdi-clock-outline
							</v-icon>
							<span class="caption grey--text font-weight-light">updated 10 minutes ago</span>
						</template>
					</material-chart-card>
				</v-flex>
				<v-flex md12 sm12 lg4>
					<material-chart-card
						:data="dataCompletedTasksChart.data"
						:options="dataCompletedTasksChart.options"
						color="green"
						type="Line"
					>
						<h3 class="title font-weight-light">Completed Tasks</h3>
						<p class="category d-inline-flex font-weight-light">Last Last Campaign Performance</p>

						<template slot="actions">
							<v-icon
								class="mr-2"
								small
							>
								mdi-clock-outline
							</v-icon>
							<span class="caption grey--text font-weight-light">campaign sent 26 minutes ago</span>
						</template>
					</material-chart-card>
				</v-flex>
				<v-flex sm6 xs12 md6 lg3>
					<material-stats-card
						color="green"
						icon="mdi-store"
						title="Revenue"
						value="$34,245"
						sub-icon="mdi-calendar"
						sub-text="Last 24 Hours"
					/>
				</v-flex>
				<v-flex sm6 xs12 md6 lg3>
					<material-stats-card
						color="orange"
						icon="mdi-content-copy"
						title="Used Space"
						value="49/50"
						small-value="GB"
						sub-icon="mdi-alert"
						sub-icon-color="error"
						sub-text="Get More Space..."
						sub-text-color="text-primary"
					/>
				</v-flex>
				<v-flex sm6 xs12 md6 lg3>
					<material-stats-card
						color="red"
						icon="mdi-information-outline"
						title="Fixed Issues"
						value="75"
						sub-icon="mdi-tag"
						sub-text="Tracked from Github"
					/>
				</v-flex>
				<v-flex sm6 xs12 md6 lg3>
					<material-stats-card
						color="info"
						icon="mdi-twitter"
						title="Followers"
						value="+245"
						sub-icon="mdi-update"
						sub-text="Just Updated"
					/>
				</v-flex>
				<v-flex md12 lg12>
					<material-card
						color="orange"
						title="Employee Stats"
						text="New employees on 15th September, 2016"
					>
						<v-data-table
							:headers="headers"
							:items="items"
						>
							<template
								slot="headerCell"
								slot-scope="{ header }"
							>
								<span
									class="font-weight-light text-warning text--darken-3"
									v-text="header.text"
								/>
							</template>
							<template
								slot="items"
								slot-scope="{ index, item }"
							>
								<td>{{ item.name }}</td>
								<td class="text-xs-right">{{ item.salary }}</td>
								<td class="text-xs-right">{{ item.country }}</td>
								<td class="text-xs-right">{{ item.city }}</td>
							</template>
						</v-data-table>
					</material-card>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Card from '@/components/material/Card.vue';
import ChartCard from '@/components/material/ChartCard.vue';
import StatsCard from '@/components/material/StatsCard.vue';
import { format, getYear, getMonth, parseISO } from 'date-fns';
import jsonToFormData from 'json-form-data';

export default {
	components: {
		'material-card': Card,
		'material-chart-card': ChartCard,
		'material-stats-card': StatsCard,
	},
	data () {
		return {
			dailySalesChart: {
				data: {
					labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
					series: [[12, 17, 7, 17, 23, 18, 38]]
				},
				options: {
					lineSmooth: this.$chartist.Interpolation.cardinal({
						tension: 0
					}),
					low: 0,
					high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
					chartPadding: {
						top: 0,
						right: 0,
						bottom: 0,
						left: 0
					}
				}
			},
			dataCompletedTasksChart: {
				data: {
					labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
					series: [[230, 750, 450, 300, 280, 240, 200, 190]]
				},
				options: {
					lineSmooth: this.$chartist.Interpolation.cardinal({
						tension: 0
					}),
					low: 0,
					high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
					chartPadding: {
						top: 0,
						right: 0,
						bottom: 0,
						left: 0
					}
				}
			},
			emailsSubscriptionChart: {
				data: {
					labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
					series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]]
				},
				options: {
					axisX: {
						showGrid: false
					},
					low: 0,
					high: 1000,
					chartPadding: {
						top: 0,
						right: 5,
						bottom: 0,
						left: 0
					}
				},
				responsiveOptions: [
					['screen and (max-width: 640px)', {
						seriesBarDistance: 5,
						axisX: {
							labelInterpolationFnc: function (value) {
								return value[0];
							}
						}
					}]
				]
			},
			headers: [
				{
					sortable: false,
					text: 'Name',
					value: 'name'
				},
				{
					sortable: false,
					text: 'Salary',
					value: 'salary',
					align: 'right'
				},
				{
					sortable: false,
					text: 'Country',
					value: 'country',
					align: 'right'
				},
				{
					sortable: false,
					text: 'City',
					value: 'city',
					align: 'right'
				}
			],
			items: [
				{
					name: 'Dakota Rice',
					country: 'Niger',
					city: 'Oud-Tunrhout',
					salary: '$35,738'
				},
				{
					name: 'Minerva Hooper',
					country: 'Curaçao',
					city: 'Sinaai-Waas',
					salary: '$23,738'
				}, 
				{
					name: 'Sage Rodriguez',
					country: 'Netherlands',
					city: 'Overland Park',
					salary: '$56,142'
				},
				{
					name: 'Philip Chanley',
					country: 'Korea, South',
					city: 'Gloucester',
					salary: '$38,735'
				}, 
				{
					name: 'Doris Greene',
					country: 'Malawi',
					city: 'Feldkirchen in Kārnten',
					salary: '$63,542'
				}
			],
			tabs: 0,
			list: {
				0: false,
				1: false,
				2: false
			}
		};
	},
	computed: {
		...mapGetters({
			trxdisbs: 'trxdisb/trxdisbs',
			currUser: 'auth/currUser',
		})
	},
	watch: {
		trxdisbs: {
			handler() {
				this.removeActiveTransaction();
			}
		},
	},
	mounted() {
		const that = this;
		let date = format(new Date(), 'yyyy-MM-dd');
		let month = getMonth(parseISO(date)) + 1;
		let year = getYear(parseISO(date));
		let i = (month.toString().length == 2 ? '-' : '-0');
		that.month_year = year + i + month;
		this.$store.dispatch('trxdisb/getTrxdisbs', {document_date: that.month_year +'-01'});
	},
	methods: {
		complete (index) {
			this.list[index] = !this.list[index];
		},
		removeActiveTransaction() {
			let txn = this.trxdisbs;

			txn = Object.values(txn);
			if (txn !== undefined) {
				txn.forEach(element => {
					if (element !== null) {
						if(element.processing === 1 && element.preparer === this.currUser.user_last_name + ', ' + this.currUser.user_first_name){
							let data = {};
							data.batch_number = element.batch_number;
							data.value = {
								processing : 0,
								document_type : element.document_type,
								status : element.status,
								_method: 'PUT'
							};

							var options = {
								initialFormData: new FormData(),
								showLeafArrayIndexes: true,
								includeNullValues: false,
								mapping: function(value) {
									if (typeof value === 'boolean') {
										return +value ? '1': '0';
									}
									return value;
								}
							};

							let convertedFormData = jsonToFormData(data.value, options);
							this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
						}
					}
				});	
			}
		}
	}
};
</script>
